/**
 * Flows
 * Flows API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConfigType = 'prime-onboarding' | 'montana-get-care' | 'weight-loss';

export const ConfigType = {
    PrimeOnboarding: 'prime-onboarding' as ConfigType,
    MontanaGetCare: 'montana-get-care' as ConfigType,
    WeightLoss: 'weight-loss' as ConfigType
};

