/**
 * Flows
 * Flows API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { HttpParameterCodec, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import Session from 'supertokens-web-js/recipe/session';
import { AuthStrategy } from '@libs/services/auth-strategy';

// @ts-ignore
import { CreateFlowDto } from '../model/createFlowDto';
// @ts-ignore
import { FlowCurrentDto } from '../model/flowCurrentDto';
// @ts-ignore
import { FlowNextDto } from '../model/flowNextDto';
// @ts-ignore
import { ProcessFlowDto } from '../model/processFlowDto';
// @ts-ignore
import { UpdateFlowDto } from '../model/updateFlowDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root'
})
export class FlowInstanceService {
  protected basePath = 'http://localhost';
  public defaultHeaders: any = {};
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration,
    @Optional() private readonly authStrategy: AuthStrategy, // Inject the strategy if provided
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  /**
   * @param createFlowDto 
   */
  public async flowInstanceControllerCreateFlowInstance(createFlowDto: CreateFlowDto, ): Promise<FlowCurrentDto> {
    if (createFlowDto === null || createFlowDto === undefined) {
      throw new Error('Required parameter createFlowDto was null or undefined when calling flowInstanceControllerCreateFlowInstance.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/flow/flows/instance`,
        data: createFlowDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param id 
   */
  public async flowInstanceControllerGetFlowInstance(id: string, ): Promise<FlowNextDto> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling flowInstanceControllerGetFlowInstance.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.get({
      url: `${this.configuration.basePath}/api/flow/flows/instance/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param id 
   * @param processFlowDto 
   */
  public async flowInstanceControllerProcessFlowInstance(id: string, processFlowDto: ProcessFlowDto, ): Promise<FlowNextDto> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling flowInstanceControllerProcessFlowInstance.');
    }
    if (processFlowDto === null || processFlowDto === undefined) {
      throw new Error('Required parameter processFlowDto was null or undefined when calling flowInstanceControllerProcessFlowInstance.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.post({
      url: `${this.configuration.basePath}/api/flow/flows/instance/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/process`,
        data: processFlowDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
  /**
   * @param id 
   * @param updateFlowDto 
   */
  public async flowInstanceControllerUpdateFlowInstance(id: string, updateFlowDto: UpdateFlowDto, ): Promise<FlowNextDto> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling flowInstanceControllerUpdateFlowInstance.');
    }
    if (updateFlowDto === null || updateFlowDto === undefined) {
      throw new Error('Required parameter updateFlowDto was null or undefined when calling flowInstanceControllerUpdateFlowInstance.');
    }

    const localVarHeaders = this.defaultHeaders;

    const token = await Session.getAccessToken();
    localVarHeaders['content-type'] = 'application/json';
    localVarHeaders['Authorization'] = `Bearer ${token}`;
    const response = await CapacitorHttp.patch({
      url: `${this.configuration.basePath}/api/flow/flows/instance/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`,
        data: updateFlowDto,
      headers: localVarHeaders,
    });
    if (response?.status >= 400) {
      if (response?.status === 401) {
        await this.authStrategy.signOut();
      }
      throw new HttpErrorResponse({
        error: response.data,
        status: response.status,
        url: response.url,
      });
    } else {
      return response.data;
    }
  }
}
