import { computed, Injectable, Signal, signal } from '@angular/core';
import { FlowService } from '@services/flow.service';
import { ConfigType, FlowManagerService } from '@swagger-services/flow';
import { SelfVisitDto } from '@swagger-services/front-door';
import { RequestSelfVisitService } from '@services/request-self-visit.service';
import { ModalService } from '@services/modal.service';
import { NavController } from '@ionic/angular/standalone';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class GetCareFlowService extends FlowService {
  private readonly _account: any = signal<any>(undefined);
  account: Signal<any> = computed(this._account);
  private readonly _frontDoor: any = signal<any>(undefined);
  navigateBack?: boolean;

  constructor(
    private requestSelfVisitService: RequestSelfVisitService,
    override readonly flowManagerService: FlowManagerService,
    override readonly modalService: ModalService,
    override readonly navController: NavController,
  ) {
    super(flowManagerService, modalService, navController);
  }

  setAccount(account: any): void {
    this._account.set(account);
  }

  setFrontDoor(frontDoor: any): void {
    this._frontDoor.set(frontDoor);
  }

  override processFlow(data?: any, currentStep?: string): Promise<any> {
    this.navigateBack = data?.back;
    return super.processFlow({
      ...data,
      currentStep: currentStep || this.flowConfig?.nextStep || this.flowConfig?.currentStep,
      targetEntityId: this.account().id,
      configType: ConfigType.MontanaGetCare,
    });
  }

  async redirectUserToNextStep(): Promise<void> {
    let url;

    switch (this.flowConfig.nextStep || this.flowConfig.currentStep) {
      // Since flow starts from submit-concern modal, processFlow will never return nextStep: 'notes' when going forward
      // 'notes' case is only for back navigation
      case 'notes': {
        url = ['care/account'];
        break;
      }
      case 'frontDoorAvailability': {
        url = [`care/${this.account().id}/intake/provider-availability`];
        break;
      }
      case 'noAvailableHours': {
        url = [`care/${this.account().id}/intake/provider-unavailable`];
        break;
      }
      case 'consent': {
        url = [`care/${this.account().id}/pre-visit/consent`];
        break;
      }
      case 'employer': {
        url = [`care/${this.account().id}/pre-visit/employer`];
        break;
      }
      case 'pcp': {
        url = [`care/${this.account().id}/pre-visit/primary-care-provider`];
        break;
      }
      case 'paymentPreference': {
        url = this.flowConfig.flowData.conditionData.hasEmployer
          ? [`care/${this.account().id}/payment-covered-confirmation`]
          : [`care/${this.account().id}/payment-preferences`];
        break;
      }
      case 'discussPayment': {
        url = [`care/${this.account().id}/payment-later`];
        break;
      }
      case 'addInsurance': {
        url = [`care/${this.account().id}/insurance/add-insurance`];
        break;
      }
      case 'confirmInsurance': {
        url = [`care/${this.account().id}/insurance/insurance-confirmation`];
        break;
      }
      case 'video-visit': {
        const frontDoorSlug = this.flowConfig.configData.frontDoorSlug;
        const payload: SelfVisitDto = { patientNotes: this.flowConfig.flowData.inputData.notes.chiefComplaint };
        if (this.flowConfig.flowData.inputData?.frontDoorAvailability?.scheduledTime) {
          payload.scheduleAtTime = this.flowConfig.flowData.inputData.frontDoorAvailability.scheduledTime;
        }
        const waitingRoom = await this.requestSelfVisitService.requestSelfVisit(payload, frontDoorSlug);

        url = [`${environment.orgSlug}/${waitingRoom.hash}`];
        break;
      }
      case 'confirmScheduleTime': {
        url = [`care/${this.account().id}/intake/provider-availability/confirm`];
        break;
      }
      case 'home':
      default: {
        url = ['home'];
        break;
      }
    }

    if (this.flowConfig.done) {
      this.clear();
      await this.navController.navigateRoot('care');
    }

    this.navigateBack ? await this.navController.navigateBack(url) : await this.navController.navigateForward(url);
  }

  clear(): void {
    this.setAccount(undefined);
    this.setFrontDoor(undefined);
    this.updateFlowData(undefined);
  }
}
