import { Injectable } from '@angular/core';
import {
  FrontDoorPatientInfoService,
  PatientFrontDoorInfoDto,
  SelfVisitDto,
  WaitingRoomDto,
} from '@swagger-services/front-door';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RequestSelfVisitService {
  private frontDoor!: PatientFrontDoorInfoDto;

  constructor(private readonly frontDoorPatientInfoService: FrontDoorPatientInfoService) {}

  async requestSelfVisit(payload: SelfVisitDto, slug: string): Promise<WaitingRoomDto> {
    if (!this.frontDoor) {
      this.frontDoor = await this.frontDoorPatientInfoService.patientFrontDoorInfoControllerFindOneBySlug(
        environment.orgSlug,
        { slug },
      );
    }

    return this.frontDoorPatientInfoService.patientFrontDoorInfoControllerRequestSelfVisit(
      environment.orgSlug,
      this.frontDoor.id,
      payload,
    );
  }
}
