import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { FlowManagerService, ProcessFlowDto } from '@swagger-services/flow';
import { ModalService } from './modal.service';
import { NavController } from '@ionic/angular/standalone';

@Injectable({ providedIn: 'root' })
export abstract class FlowService {
  private readonly _flowConfig$ = new BehaviorSubject<any | undefined>(undefined);

  constructor(
    protected readonly flowManagerService: FlowManagerService,
    protected readonly modalService: ModalService,
    protected readonly navController: NavController,
  ) {}

  get flowConfig(): any | undefined {
    return this._flowConfig$.value;
  }

  get flowConfig$(): Observable<any | undefined> {
    return this._flowConfig$.asObservable();
  }

  updateFlowData(inviteFlow: any): void {
    this._flowConfig$.next(inviteFlow);
  }

  async processFlow(data: ProcessFlowDto): Promise<any> {
    try {
      const config = await this.flowManagerService.flowManagerPatientControllerProcessFlow(environment.orgSlug, data);
      this.updateFlowData(config);
      this.redirectUserToNextStep();
      return config;
    } catch {
      await this.modalService.openErrorModal();
    }
  }

  // Abstract method that must be implemented by the derived class
  protected abstract redirectUserToNextStep(): void;
}
